// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'tekoop.scss';
 
// Bootstrap
@import '~bootstrap/scss/bootstrap';

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
  }
  .navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    font-size: 17px;
    margin-top: 10px;
  }
  .navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body {
    padding: 15px;
    border-bottom: 1px solid #f4f4f4;
    border-top: 1px solid #dddddd;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body:before,
  .navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
    content: " ";
    display: table;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
    clear: both;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    color: #444 !important;
  }
  @media (max-width: 991px) {
    .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
      background: #fff !important;
      color: #444 !important;
    }
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer {
    background-color: #f9f9f9;
    padding: 10px;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer:before,
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
    content: " ";
    display: table;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
    clear: both;
  }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #666666;
  }
  @media (max-width: 991px) {
    .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
      background-color: #f9f9f9;
    }
  }
  .navbar-nav > .user-menu .user-image {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
  }
  @media (max-width: 767px) {
    .navbar-nav > .user-menu .user-image {
      float: none;
      margin-right: 0;
      margin-top: -8px;
      line-height: 10px;
    }
  }

//@import 'changepassword';
@import '../js/website/pages/tekoop.scss';



.card.eventDisplay {
  box-shadow: .25rem .25rem 1.75rem #B8B8B8;
  border: none;
}
.btn.btn-black {
  background: #000;
  color: white;
  border-radius: 2.5rem;
}
.card-img.card-img-bottom {
  border-radius: .25rem .25rem 0 0;
}
@media (min-width: 48em) and (max-width: 61em) {
  .event:nth-child(2n+1) {
    clear: left;
  }
}
@media (min-width: 62em) {
  .event:nth-child(3n+1) {
    clear: left;
  }
}